import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavigationComponent } from './navigation/navigation.component';
import { LayoutModule } from '@angular/cdk/layout';
import {
  MatToolbarModule, MatButtonModule, MatSidenavModule, MatIconModule, MatListModule,
  MatGridList, MatGridListModule, MatMenuModule, MatCardModule, MatTabGroup, MatTabsModule, MatFormFieldModule,
  MatOptionModule, MatTableModule, MatSelectModule, MatInputModule, MatPaginatorModule, MatSortModule, MatButtonToggle,
  MatButtonToggleModule, MatDatepickerModule, MatNativeDateModule
} from '@angular/material';
import { FooterComponent } from './footer/footer.component';
import { AboutComponent } from './about/about.component';
import {RouterModule, Routes} from "@angular/router";
import { SuggestComponent } from './suggest/suggest.component';
import { EventsComponent } from './events/events.component';
import {SuggesteventService} from './suggest/suggestevent.service';
import {CanadaService} from './services/canada.service';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {ErrorStateMatcher} from '@angular/material';
import {AgmCoreModule} from '@agm/core';
import { ProgressbarComponent } from './progressbar/progressbar.component';
import {ProgressbarserviceService} from './services/progressbarservice.service';
import {ShowOnDirtyErrorStateMatcher} from '@angular/material';
import {ReactiveFormsModule} from '@angular/forms';
import {MatStepperModule} from '@angular/material/stepper';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { AdminComponent } from './admin/admin.component';
import { AdminsecureComponent } from './adminsecure/adminsecure.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import { DiagComponent } from './diag/diag.component';
import {MatDialogModule} from '@angular/material/dialog';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {CrawlerService} from './services/crawler.service';
import {HttpClientModule} from '@angular/common/http';


const routes: Routes = [
  { path: '', component: EventsComponent },
  { path: 'about', component: AboutComponent },
  { path: 'suggest', component: SuggestComponent },
  {path: 'admin', component: AdminsecureComponent},
  {path: 'adminsecure', component: AdminComponent}
];

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    FooterComponent,
    AboutComponent,
    SuggestComponent,
    EventsComponent,
    ProgressbarComponent,
    AdminComponent,
    AdminsecureComponent,
    DiagComponent,
  ],
  imports: [
    RouterModule.forRoot(
      routes,
      { enableTracing: true } // <-- debugging purposes only
    ),
    MatProgressSpinnerModule,
    BrowserModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatTabsModule,
    MatFormFieldModule,
    MatOptionModule,
    MatTableModule,
    MatSelectModule,
    MatInputModule,
    MatPaginatorModule,
    MatSortModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBxsqW4qYptx5ZxuySBPw1pdAeFU1h5Uuc'
    }),
    ReactiveFormsModule,
    MatStepperModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    FlexLayoutModule,
    MatBottomSheetModule,
    MatDialogModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    HttpClientModule
  ],
  providers: [SuggesteventService, CanadaService, ProgressbarserviceService, {provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher}, CrawlerService],
  bootstrap: [AppComponent]
})
export class AppModule { }
