import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import {EventsItem} from '../events/EventsItem';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SuggesteventService {
  items: EventsItem[] = new Array();
  valid: boolean;
  router: Router
  constructor(router: Router) {
  this.router = router;
  }

  suggest(name: string, organization: string, description: string, link: string, address: string, postalcode: string,
          province: string, city: string, time: string, active: boolean, imageLink: string, date: string, email: string,
          phoneNumber: string, id: number, lat: number, long: number) {
    const db = firebase.firestore();
    city = city[0] + city.substring(1, city.length).toLowerCase();
    var exists = false;
    var i;
    for (i = 0; i < this.items.length; i++) {
     if (name.toLowerCase() === this.items[i].name.toLowerCase() || description.toLowerCase() === this.items[i].description.toLowerCase()) {
       exists = true;
     }
    }
    console.log(exists);
    if (exists === false && i === this.items.length) {
      db.collection('events').add({
        name: name,
        organization: organization,
        description: description,
        link: link,
        address: address,
        postalcode: postalcode,
        province: province,
        city: city,
        time: time,
        id: id,
        active: active,
        date: date,
        imageLink: imageLink,
        email: email,
        phoneNumber: phoneNumber,
        lat: lat,
        long: long,

      }).then(docRef => {
        this.getSize(docRef.id);
        this.VALID(true);
      });
    }

    else{
      alert('This event already exists');
      this.VALID(false);
    }

  }

  suggest2(name: string, organization: string, description: string, link: string, address: string, postalcode: string,
          province: string, city: string, time: string, active: boolean, imageLink: string, date: string, email: string,
          phoneNumber: string) {
    const db = firebase.firestore();
    let i;
    let lat;
    let long;
    const geocoder = new google.maps.Geocoder()
    const address2 = address + ', ' + city + ', ' + province + ' ' + postalcode;
    geocoder.geocode({'address': address2}, ((results, status1) => {
      if (status1 === google.maps.GeocoderStatus.OK) {
        lat = results[0].geometry.location.lat();
        long = results[0].geometry.location.lng();
        city = city[0] + city.substring(1, city.length).toLowerCase();
          db.collection('events').add({
            name: name,
            organization: organization,
            description: description,
            link: link,
            address: address,
            postalcode: postalcode,
            province: province,
            city: city,
            time: time,
            active: active,
            date: date,
            imageLink: imageLink,
            email: email,
            phoneNumber: phoneNumber,
            lat: lat,
            lng: long
          }).then(docRef => {
            this.getSize2(docRef.id);
          });
      } else {
        console.log(google.maps.GeocoderStatus);
      }
    }));
  }

  VALID(val: boolean) {
    this.valid = val;
  }

  getVALID() {
    return this.valid;
  }

  getSize(docRef: string) {
    const db = firebase.firestore();
    const docRef1 = db.collection('events');

    docRef1.get().then((querySnapshot) => {
      this.updatewithRef(docRef);
    });

  }

  getSize2(docRef: string) {
    const db = firebase.firestore();
    const docRef1 = db.collection('idcreator').doc('GKA0x9Sw8CTtL2qE4AM8').get().then((doc) => {
      this.updatewithRef2(docRef, doc.data().nextID);
    });

  }
  updatewithRef(docRef: string) {

    const db = firebase.firestore();
    const eventsref = db.collection('events').doc(docRef);
    return eventsref.update({
      docRef: docRef,
    }).then(function () {
      console.log('Document sucessfully added');
      this.router.navigateByUrl('/');
    }).catch(function (error) {
      console.log('Error Adding Document');
    });
  }

  updatewithRef2(docRef: string, size: number) {

    const db = firebase.firestore();
    const eventsref = db.collection('events').doc(docRef);
    return eventsref.update({
      docRef: docRef,
      id: size
    }).then(() => {
      console.log('Document sucessfully added');
      this.incrementNextID(size);
    }).catch(function (error) {
      console.log(error);
    }).catch((error) => {
      console.log(error);
    });
  }

  incrementNextID(size: number) {
    console.log(size);
    const size1 = size + 1;
    const nextID = size1;
    console.log(nextID);
    const db = firebase.firestore();
    const docRef = db.collection('idcreator').doc('GKA0x9Sw8CTtL2qE4AM8').update({
      nextID: nextID
    }).then(() => {
      alert('Event suggested successfully')
      this.router.navigateByUrl('/');
    }).catch((error) => {
      console.log(error);
    });
  }

  // getEvents(name: string, organization: string, description: string, link: string, address: string, postalcode: string,
  // province: string, city: string, time: string, active: boolean, imageLink: string, date: string, email: string,
  // phoneNumber: string) {
  //   const db = firebase.firestore();
  //   db.collection('events').get().then((querySnapshot) => {
  //     this.items = new Array();
  //     querySnapshot.forEach((doc) => {
  //       // Getting Item Information from Firebase
  //       const item = new EventsItem();
  //       item.id = doc.data().id
  //       item.name = doc.data().name;
  //       item.description = doc.data().description;
  //       item.link = doc.data().link;
  //       item.city = doc.data().city;
  //       item.time = doc.data().time;
  //       item.active = doc.data().active;
  //       item.address = doc.data().address;
  //       console.log(doc.data().imageLink);
  //       item.imageLink = doc.data().imageLink;
  //
  //       this.items.push(item);
  //     });
  //     this.suggest2(name, organization, description, link, address, postalcode,
  //       province, city, time, false, imageLink, date, email, phoneNumber);
  //
  //   });
  // }
}
