import {Component, EventEmitter, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ProgressbarserviceService} from '../services/progressbarservice.service';
import {secure} from '../adminsecure/secure';
import * as firebase from 'firebase';
import {EventsItem} from '../events/EventsItem';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  authorized: boolean;
  router: Router;
  sharedService: ProgressbarserviceService;
  inactiveEvents: EventsItem[] = new Array()
  activeEvents: EventsItem[] = new Array();
  selectedOptions:any
  constructor(router: Router, bb: ProgressbarserviceService) {
    this.authorized = false;
    this.router = router;
    this.sharedService = bb;
  }

  ngOnInit() {
    this.authorized = secure.getInstance().getSecure()
    this.sharedService.change();
    if (this.authorized === false) {
      console.log('Here')
      this.router.navigateByUrl('/admin');
    }

    this.getEvents();
  }

  getEvents() {

    console.log('Here')

    const db = firebase.firestore();

    db.collection('events').orderBy('id').onSnapshot((querySnapshot) => {
      console.log('Here')
      this.activeEvents = new Array()
      this.inactiveEvents = new Array()
      querySnapshot.forEach((doc) => {
        if (doc.data().active) {
          const item = new EventsItem();
          item.id = doc.data().id
          item.name = doc.data().name;
          item.description = doc.data().description;
          item.link = doc.data().link;
          item.city = doc.data().city;
          item.time = doc.data().time;
          item.active = doc.data().active;
          item.address = doc.data().address;
          item.imageLink = doc.data().imageLink;
          item.docRef = doc.data().docRef;
          this.activeEvents.push(item);

          console.log(item);
        } else if (doc.data().active === false) {
          const item = new EventsItem();
          item.id = doc.data().id
          item.name = doc.data().name;
          item.description = doc.data().description;
          item.link = doc.data().link;
          item.city = doc.data().city;
          item.time = doc.data().time;
          item.active = doc.data().active;
          item.address = doc.data().address;
          item.imageLink = doc.data().imageLink;
          item.docRef = doc.data().docRef;
          this.inactiveEvents.push(item);
        }

        console.log(this.inactiveEvents);
      });
    });
  }

  delete(val: any) {
    console.log(val);
    console.log(val.length);
    const db = firebase.firestore();
    for (var i = 0; i < val.length; i++) {
      console.log(val[i].value)
      db.collection('events').doc(val[i].value).delete().then(function () {
        console.log('Event Deleted');
      });
    }
  }

  active(val: any){
    console.log(val.length);
    const db = firebase.firestore();
    for(var i = 0; i < val.length; i++){
      db.collection('events').doc(val[i].value).update({
        active: true
      });
    }
  }

  inactive(val:any){
    console.log(val)
    const db = firebase.firestore();
    for(var i = 0; i < val.length; i++){
      db.collection('events').doc(val[i].value).update({
        active: false
      }).catch(function (error) {
        console.log(error)

      });
    }
  }

}
