import { Component, OnInit } from '@angular/core';
import {ProgressbarserviceService} from '../services/progressbarservice.service';
import {Router} from '@angular/router';
import {secure} from './secure';
import * as firebase from 'firebase';

@Component({
  selector: 'app-adminsecure',
  templateUrl: './adminsecure.component.html',
  styleUrls: ['./adminsecure.component.css']
})
export class AdminsecureComponent implements OnInit {
  sharedService: ProgressbarserviceService;
  loginCode: string;
  loginPass: string;
  router: Router;
  constructor(ss: ProgressbarserviceService, router: Router) {
    this.sharedService = ss;
    this.sharedService.change();
    this.loginCode = '';
    this.loginPass = '';
    this.router = router;
  }

  ngOnInit() {
    this.sharedService.change();
  }

  signIn() {
    if (this.loginCode.toLowerCase() === 'objex' && this.loginPass === 'AuI0967') {
      secure.getInstance().isSecure(true);
      this.router.navigateByUrl('adminsecure');
    }
  }
}
