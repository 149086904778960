import {Component, EventEmitter, OnInit} from '@angular/core';
import { MatGridList, MatGridTile, MatCard, MatCardContent, MatTabGroup } from '@angular/material';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Canada Day';
  diplay: string;

  constructor() {
  }

  ngOnInit() {
  }
}
