import {EventEmitter, Injectable, Output} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProgressbarserviceService {
  @Output() fire: EventEmitter<any> = new EventEmitter<any>();
  @Output() dataChangeObserver: EventEmitter<any> = new EventEmitter();
  data: any;
  constructor() { }

  change() {
    this.fire.emit(false);
  }

  changeHome(val: boolean) {
    this.fire.emit(true);
  }

  getEmittedValue() {
    return this.fire;
  }

  setData(data: boolean) {
    this.data = data;
    this.dataChangeObserver.emit(this.data);
    return this.dataChangeObserver;
  }

  loadedFire(val: boolean){
    return val;
  }
}
