import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
declare function require(name: string);

@Injectable({
  providedIn: 'root'
})
export class CrawlerService {

  data: any;


  constructor(private http: HttpClient) { }

  crawl() {
    // var crawler = require('crawler');
    // var c = new crawler({
    //   callback : function (error, res, done) {
    //     if (error){
    //       console.log(error);
    //     }else{
    //       var $ = res.$;
    //       console.log($('title').text());
    //     }
    //     done();
    //   }
    // });
    //
    // c.queue('https://www.canada.ca/en/canadian-heritage/campaigns/celebrate-canada-days/calendar.html');

    const url = 'https://www.canada.ca/content/dam/pch/json/GC03_Celebrate.json';
    this.http.get(url).subscribe((data) => {
      this.data = data;
      console.log(this.data);
      return this.data;
    });
  }
}
