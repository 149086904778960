import { Component, OnInit } from '@angular/core';
import { MatFormField, MatSelect, MatOption } from '@angular/material';
import * as firebase from 'firebase/app';
import {SuggesteventService} from './suggestevent.service';
import {Province} from '../services/Province';
import {CanadaService} from '../services/canada.service';
import {ProgressbarserviceService} from '../services/progressbarservice.service';
import {ErrorStateMatcher} from '@angular/material';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {delay} from 'q';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatSnackBar} from '@angular/material';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

declare function require(name: string);
declare const $: any;

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-suggest',
  templateUrl: './suggest.component.html',
  styleUrls: ['./suggest.component.css']
})

export class SuggestComponent implements OnInit {
  name: string;
  organization: string;
  description: string;
  link: string;
  address: string;
  postalcode: string;
  province: string;
  city: string;
  time: string;
  suggestEventS: SuggesteventService;
  canS: CanadaService;
  sharedService: ProgressbarserviceService;
  // Array of Provinces
  provinces: Province[];
  dialogHeader: string;
  dialogData: string;
  conf: boolean;
  message: string;
  router: Router;
  imageLink: string;
  realEvent: any;
  file: File;
  progress: string;
  date: string;
  isLinear: boolean;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  email: string;
  phoneNumber: string;
  snackBars: MatSnackBar;

  cities: string[];

  myControl: FormControl = new FormControl();

  filteredOptions: Observable<string[]>;


  constructor(suggestEventS: SuggesteventService, canService: CanadaService, ss: ProgressbarserviceService, router: Router, private _formBuilder: FormBuilder, public snackBar: MatSnackBar) {
    this.name = '';
    this.organization = '';
    this.description = '';
    this.link = 'http://';
    this.time = '';
    this.address = '';
    this.postalcode = '';
    this.province = '';
    this.city = '';
    this.suggestEventS = suggestEventS;
    this.canS = canService;
    this.provinces = this.canS.getProvinces();
    this.sharedService = ss;
    this.conf = false;
    this.message = '';
    this.router = router;
    this.date = '7/1/2018';
    this.email = '';
    this.phoneNumber = '';
    this.snackBars = snackBar;
    this.imageLink = '';
  }

  ngOnInit() {
    this.sharedService.change();


    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required],
      secondCtrl: ['', Validators.required],
      thirdCtrl: ['', Validators.required],
      fourthCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required],
      secondCtrl: ['', Validators.required],
    });

    this.thirdFormGroup = this._formBuilder.group({
      firstCtrl: ['', [Validators.required, Validators.email]],
    });


  }

  async suggestEvent() {
    console.log('Here')
    console.log(this.name)
    console.log(this.description);
    if (this.name !== '' && this.organization !== '' && this.description !== '' && this.address !== '' &&
    this.postalcode !== '' && this.province !== '' && this.city !== '' && this.time !== '' && this.email !== '') {

      this.suggestEventS.suggest2(this.name, this.organization, this.description, this.link, this.address, this.postalcode,
        this.province, this.city, this.time, false, this.imageLink, this.date, this.email, this.phoneNumber);
    } else {
      this.message = 'Please provide the required data.' + this.city;
      this.conf = true;
    }


  }

  filter(val: string): string[] {
    return this.cities.filter(cit =>
      cit.toLowerCase().includes(val.toLowerCase()));
  }

  uploadFile(event: any) {
    this.imageLink = this.imageLink.replace(/\\/g, '/')
    console.log(this.imageLink);
    const array = this.imageLink.split('/');
    this.imageLink = array[array.length - 1];

    console.log(this.imageLink);
    const fileName = this.imageLink;
    this.imageLink = this.name + '/' + this.organization + '/' + fileName;
    this.realEvent = event;

    if (this.imageLink != null) {
      const storageRef = firebase.storage().ref();
      const imageRef = storageRef.child(this.imageLink);
      this.file = this.realEvent.target.files;
      console.log(this.file[0])
      document.getElementById('displayProg').style.visibility = 'visible';
      var uploadTask = imageRef.put(this.file[0])
      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
        this.progress = ((uploadTask.snapshot.bytesTransferred / uploadTask.snapshot.totalBytes) * 100).toString();
        console.log('Upload is ' + this.progress + '% done');
      });

      uploadTask.then((snapshot) => {
        document.getElementById('displayProg').style.visibility = 'hidden';
        const snackBarRef = this.snackBars.open('Image Uploaded');
        this.progress = '0';

      });

    }

  }



  getCities(prov: string) {
    this.cities = this.canS.getCityForProvince(prov);

    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(val => this.filter(val))
      );
  }

  actualUP() {

  }

}
