import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { EventsDataSource } from './events-datasource';
import {EventsItem} from './EventsItem';
import * as firebase from 'firebase/app';
import {MatDialog} from '@angular/material';
import {} from '@types/googlemaps';
import {MapsAPILoader} from '@agm/core';
import {Observable, Observer} from 'rxjs';
import {ProgressbarserviceService} from '../services/progressbarservice.service';
import {delay} from 'q';
import {CrawlerService} from '../services/crawler.service';
import {HttpClient} from '@angular/common/http';
import {SuggesteventService} from '../suggest/suggestevent.service';

declare function require(name: string);
declare const $: any;
declare var google: any;

@Component({
  selector: 'events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: EventsDataSource;
  items: EventsItem[] = new Array();
  itemJSON: EventsItem[] = new Array();

  // Mapping stuff
  @ViewChild('gmap') gmapElement: any;
  @ViewChild('gmap2') gmapEl: any;
  map: google.maps.Map;
  map2: google.maps.Map;
  isTracking = false;
  currentLat: any;
  currentLong: any;
  theirLocationLat: any;
  theirLocationLng: any;
  addressD: string;
  nameD: string;
  descriptionD: string;
  latitude: number;
  longitude: number;
  zoom: number;
  sharedService: ProgressbarserviceService;
  display: string;
  marker: google.maps.Marker;
  marker2: google.maps.Marker;
  mapProp: any
  directionsDisplay: any;
  directionsService: any;
  val: boolean
  length1: number;
  progVal: string;
  crawl: CrawlerService;
  suggestS: SuggesteventService;
  datag: any;
  tabvalue: number
  showProg: boolean;
  showTable: boolean;
  userCity: string;
  db: any;
  doneLoad: boolean;
  showProgMap: boolean;
  showMap: boolean;
  loadText: string;
  varyingD: any;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['id', 'name', 'description', 'city', 'time', 'gThere'];

  constructor(ss: ProgressbarserviceService, public dialog: MatDialog, crawler: CrawlerService, private http: HttpClient, suggestE: SuggesteventService) {
    this.sharedService = ss;
    this.val = true;
    this.crawl = crawler;
    this.suggestS = suggestE;
    this.showProg = false;
    this.showTable = true;
    this.showProgMap = false;
    this.showMap = true;
    this.db = firebase.firestore();
    const settings = {timestampsInSnapshots: true};
    this.loadText = 'Loading event map. Please Wait'
    this.db.settings(settings);
  }

  ngOnInit() {
    //map initialization
    this.setMap();
    // find user
    this.findMe();
    //track user
    //this.trackMe();
  }
  setMap() {
    this.mapProp = {
      center: new google.maps.LatLng(45.421800, -75.701893),
      zoom: 5,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    this.map = new google.maps.Map(this.gmapElement.nativeElement, this.mapProp);
    this.map.setZoom(10);
    //this.varyingD = this.getRadius() * 1000;
    this.map2 = new google.maps.Map(this.gmapEl.nativeElement, this.mapProp);
    this.map2.setZoom(10);
  }
  removeProg(){
    this.showProg = true;
    this.showTable = false;
  }

  removeProgMap() {
    this.showProgMap = true;
    this.showMap = false;
  }
  setTable() {
    this.dataSource = new EventsDataSource(this.paginator, this.sort, this.items);
    if (this.dataSource != null) {
      this.length1 = this.dataSource.data.length;
    }
  }

  distance() {

    function compare(a,b) {
      if (a.distance < b.distance) {
        return -1;
      }
      if (a.distance > b.distance) {
        return 1;
      }
      return 0;
    }
    const newItem = new Array();
    const itemsSorted = new Array();
    this.map.setZoom(10);
    //const varyingD = this.getRadius() * 1000;
    console.log(this.varyingD);
    if (this.items.length > 1) {
      for (let i = 0; i < this.items.length; i++) {
        const d = this.getDistance(this.items[i].lat, this.items[i].lng);
        if (d <= this.varyingD) {
          newItem.push({id: this.items[i].id, distance: d});
        }
      }
      newItem.sort(compare);
      console.log(newItem);
      for (let i = 0; i < newItem.length; i++) {
        for (let j = 0; j<this.items.length; j++) {
          if (newItem[i].id === this.items[j].id) {
            itemsSorted.push(this.items[j]);
          }
        }
      }
    }
    console.log(itemsSorted)
    if (this.items.length >= 1) {
      this.items = itemsSorted;
      this.setTable();
    }
    for (let i = 0; i < this.items.length; i++) {
      const address1 = this.items[i].pcCode;
      this.plotMarker(this.items[i].lat, this.items[i].lng, this.items[i].description, this.items[i].address, this.items[i].name, this.items[i].link, this.items[i].imageLink);
    }
  }

  getRadius() {
    const bounds = this.map.getBounds();

    const center = bounds.getCenter();
    const ne = bounds.getNorthEast();

    // r = radius of the earth in statute miles
    const r = 3963.0;

    // Convert lat or lng from decimal degrees into radians (divide by 57.2958)
    const lat1 = center.lat() / 57.2958;
    const lon1 = center.lng() / 57.2958;
    const lat2 = ne.lat() / 57.2958;
    const lon2 = ne.lng() / 57.2958;

    // distance = circle radius from center to Northeast corner of bounds
    const dis = r * Math.acos(Math.sin(lat1) * Math.sin(lat2) +
      Math.cos(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1));

    return dis;
  }

    getDistance(itemLat: any, itemLng) {
      const distance = google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(itemLat, itemLng),
        new google.maps.LatLng(this.theirLocationLat,this.theirLocationLng));
      return distance;
    }
  getData() {
    this.directionsDisplay = new google.maps.DirectionsRenderer();
    this.directionsService = new google.maps.DirectionsService();
    var count = 0;
    console.log('here');
    console.log(this.userCity);
    this.db.collection('events').where('province', '==', this.userCity).orderBy('id').onSnapshot((querySnapshot) => {
      console.log('snapshot');
      this.val = false;
      this.items = new Array();
      count = 0;
      this.tabvalue = 1;
      querySnapshot.forEach((doc) => {
        count++;
        this.progVal = (count / querySnapshot.size * 100).toString();
        if (this.progVal === '100') {
          this.removeProg();
        }
        // Getting Item Information from Firebase
        const item = new EventsItem();
        item.id = doc.data().id
        item.name = doc.data().name;
        item.description = doc.data().description;
        item.link = doc.data().link;
        item.city = doc.data().city;
        item.time = doc.data().date;
        item.active = doc.data().active;
        item.address = doc.data().address;
        item.pcCode = doc.data().province + ' ' + doc.data().postalcode
        item.lng = doc.data().lng;
        item.lat = doc.data().lat;
        item.docRef = doc.data().docRef;
        //console.log(doc.data().imageLink);
        item.imageLink = doc.data().imageLink;
        // Checking if item is active
        if (doc.data().active) {
          const current = this.items.length - 1;
          this.items.push(item);
        }
      });
      console.log(this.items);
      this.setD()
      this.setTable();
    });
  }

  setD() {
    //this.map.setZoom(10);
    console.log(this.map);
    this.varyingD = this.getRadius() * 1000;
    if (this.varyingD < 1000) {
      this.varyingD = 50000;
    }
    this.distance();
  }

  findCity() {
    const lat = this.theirLocationLat.toString();
    const lng = this.theirLocationLng.toString();
    var latlng = {lat: parseFloat(lat), lng: parseFloat(lng)};
    const geocoder = new google.maps.Geocoder()
    geocoder.geocode({'location': latlng}, (results, status) => {
      if(status === 'OK') {
        if (results[0]) {
          console.log(results[0].formatted_address);
          const arrayAddress = results[0].formatted_address.split(',');
          console.log(arrayAddress);
          this.userCity = arrayAddress[2].split(' ');
          this.userCity = this.userCity[1];
          console.log(this.userCity);
          this.getData();
        }
      }
    });

  }


  plotMarker(lat: number, long: number, description: string, address: string, name: string, weblink: string, imageLink: string) {

    const contentString = '<div id="content">' +
      '<div id="siteNotice">' +
      '</div>' +
      '<h3 id="firstHeading" class="firstHeading"> ' + name + '</h3>' +
      '<div id="bodyContent">' +
      '</div>' +
      '<p>' + description + '</p>' +
      '<p>' + address + '</p>' +
      '<button id="clickable" >Take me there</button>' +
      '<p>Close window if you want another direction on map</p>' +
      '</div>' +
      '</div>';

    const infowindows = new google.maps.InfoWindow({
      content: contentString,
    });
    const lap = this.map;
    const lap2 = this.map2;

    google.maps.event.addListener(infowindows, 'domready', () => {
      document.getElementById('clickable').addEventListener('click', () => {
        var request = {
          origin: new google.maps.LatLng(this.theirLocationLat, this.theirLocationLng),
          destination: new google.maps.LatLng(lat, long),
          travelMode: google.maps.TravelMode['DRIVING'],
        };

        this.directionsService.route(request, (response, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            this.directionsDisplay.setPanel(document.getElementById('right-panel'));
            this.directionsDisplay.setDirections(response);
            this.directionsDisplay.setMap(lap);
          }

          else {
            console.log('Not successfull');
          }
        })
      });
    })

    var marker, i;
    var marker2;
    this.map.setZoom(10);
    this.map2.setZoom(10);
    marker = new google.maps.Marker({
      position: new google.maps.LatLng(lat, long),
      icon: 'assets/images/flag-icon-canada.png',
      map: this.map,
      clickable: true
    });

    marker2 = new google.maps.Marker({
      position: new google.maps.LatLng(lat, long),
      icon: 'assets/images/flag-icon-canada.png',
      map: this.map2,
      clickable: true
    });

    infowindows.addListener('click', event => {

    });


    marker.addListener('click', () => {
      infowindows.open(this.map, marker);
    });

    marker2.addListener('click', () => {
      infowindows.open(this.map2, marker);
    });
  }

  findMe() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        console.log(position)
        this.showPosition(position);
        this.removeProgMap();
      });
    } else {
      alert("Geolocation is not supported by this browser.");
      this.loadText = 'Problem loading map';
    }
  }

  showPosition(position) {
    this.currentLat = position.coords.latitude;
    this.currentLong = position.coords.longitude;

    let location = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
    this.map.panTo(location);
    this.map2.panTo(location);



    //Their location coords

    this.theirLocationLat = position.coords.latitude;
    this.theirLocationLng = position.coords.longitude;

    if (!this.marker) {
      this.marker = new google.maps.Marker({
        position: location,
        map: this.map,
        title: 'Your Location'
      });
    } else {
      this.marker.setPosition(location);
      // this.marker.setPosition(new google.maps.LatLng(45.4236,75.7009));
    }

    this.findCity();
  }

  trackMe() {
    if (navigator.geolocation) {
      this.isTracking = true;
      navigator.geolocation.watchPosition((position) => {
        this.showTrackingPosition(position);
      });
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  showTrackingPosition(position) {
    console.log(`tracking postion:  ${position.coords.latitude} - ${position.coords.longitude}`);
    this.currentLat = position.coords.latitude;
    this.currentLong = position.coords.longitude;
    let location = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
    this.map.panTo(location);

    if (!this.marker) {
      this.marker = new google.maps.Marker({
        position: location,
        map: this.map,
        title: 'Got you!'
      });
    }
    else {
      this.marker.setPosition(location);
    }

    if (!this.marker2) {
      this.marker2 = new google.maps.Marker({
        position: location,
        map: this.map2,
        title: 'Got you!'
      });
    }
    else {
      this.marker2.setPosition(location);
    }
  }

  openDiag(address: string, description: string, name: string, city: string, lat: number, lng: number) {

    this.addressD = address;
    this.descriptionD = description;
    this.nameD = name;
    const address1 = this.addressD + ',' + city;
    this.latitude = lat;
    this.longitude = lng;
    this.zoom = 17;

    const lap = this.map2;

    var request = {
      origin: new google.maps.LatLng(this.theirLocationLat, this.theirLocationLng),
      destination: new google.maps.LatLng(lat, lng),
      travelMode: google.maps.TravelMode['DRIVING'],
    };

    this.directionsService.route(request, (response, status) => {
      if (status === google.maps.DirectionsStatus.OK) {
        this.directionsDisplay.setPanel(document.getElementById('right-panel'));
        this.directionsDisplay.setDirections(response);
        this.directionsDisplay.setMap(lap);
      }

      else {
        console.log('Not successfull');
      }
    })

    const mdcDialog = require('@material/dialog');
    const MDCDialog = mdcDialog.MDCDialog;
    const MDCDialogFoundation = mdcDialog.MDCDialogFoundation;
    const util = mdcDialog.util;

    const dialog = new MDCDialog(document.querySelector('#viewEventMore'));

    dialog.show();

  }
}
