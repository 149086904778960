export class secure {

  private static secure1: secure = null;
  issecure = false;
  constructor() {}
  static getInstance() {
    if (this.secure1 == null) {
      this.secure1 = new secure();
    }

    return this.secure1;
  }

  isSecure(val: boolean) {
    this.issecure = val;
  }

  getSecure(): boolean {
    return this.issecure;
  }
}
