export class EventsItem {
  name: string;
  id: string;
  description: string;
  link: string;
  city: string;
  time: string;
  active: boolean;
  address: string;
  imageLink: string;
  lat: number;
  lng: number;
  docRef: string;
  pcCode: string
}
